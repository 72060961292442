import "./h_f_style.css";
import { ReactComponent as CCircle } from "./c-circle.svg";
import { ReactComponent as Facebook } from "./facebook.svg";
import { ReactComponent as Linkedin } from "./linkedin.svg";

const Footer = () => {
	const baseURL = "https://www.stellarservices.com";
    return (
        <>
			<div>
				<div className="rectangle2">
				<br/><br/>
					<div className="row everything footer">
						
						<div className="col-lg-2 col-6 links">
							<h4>Links:</h4>
								<a href={baseURL+"/ServicesHub.html"} className="link1">Services </a>
							<br/>
								<a href={baseURL+"/CaseStudies.html"} className="link1">Case Studies</a>
							<br/>
								<a href={baseURL+"/ClientsnPartners.html"} className="link1">Clients & Partners</a>
							<br/>
								<a href={baseURL+"/OurTeams.html"} className="link1">Our Teams </a>
							<br/>
								<a href={baseURL+"/AboutUs.html"} className="link1">About Stellar </a>
							<br/>
								<a href={baseURL+"/Careers.html"} className="link1">Careers</a>
							<br/><br/>
						</div>

						<div className="col-lg-8 footerm">
							<h4 className="officetitle">Offices:</h4>
							
							<div className="row address">
								<div className="col-md-4 col-6">
								<p> <strong>NYC Headquarters</strong><br/>
									70 W 36th St., 7th FL <br/>
									New York, NY 10018<br/>
								</p>
								</div>
								<div className="col-md-4 col-6">
								<p className="font12"> <strong>Atlanta, GA</strong><br/>
								448 East Paces Ferry Rd, <br/> NE Atlanta, GA 30305
								</p>
								</div>
								<div className="col-md-4 col-6">
								<p className="font12"> <strong>San Francisco, CA</strong><br/>
									1 Sansome Street, Suite 3500, <br/> San Francisco, CA, 94104
								</p>
								</div>

								<div className="col-md-4 col-6">
								<p className="font12"> <strong>McLean, VA</strong><br/>
									1765 Greenboro Station Place #900, <br/> McLean, VA 22102
								</p>
								<br/>
								</div>
							
								<div className="col-md-4 col-6">
								<p className="font12"> <strong>Houston, TX</strong><br/>
									2517 E Nasa Parkway Suite F-5 <br/>
									Seabrook,Texas 77586					 </p>
								</div>

								<div className="col-md-4 col-6">
									<p className="font12"> <strong>New Hope, PA</strong><br/>
										20 Village Row, <br/> New Hope, PA 18938					 </p>
								</div>	

								<div className="col-md-4 col-6">
								</div>
								
							</div>
						</div>
						

						<div id="contact" className="col-lg-2 col-6">
							<h4>Contact:</h4>
							<div className="row number">
								<p className="col-lg-12"> Phone: <a className="col-lg-12" href="tel:212.432.2848">212.432.2848</a> <br/>     
									<a href = "mailto:info@stellarservices.com" className="contactlink">info@stellarservices.com</a></p>

								<p className="col-lg-12"> <strong>Certified <br/> Minority-Owned <br/>Business Enterprise</strong><br/>
									</p>
								<a  className="col-lg-12 contactlink" target="blank" href="https://stellarus.sharepoint.com/">Staff Login</a>

							</div>
						</div>
						
						
					</div>
				<br/>
				</div>
				<div className="rectanglefooter">
					<div>
						<p className="copywrite">
							<i className="bi bi-c-circle"> </i>  
							<CCircle/>&nbsp;
							2022 Stellar Services</p>
							
						<p className="follow">Follow Stellar:
						</p>
						
						
						<a href="https://www.facebook.com/" className="btn fb" role="button">
							<Facebook/>
						</a>
						<a href="https://www.linkedin.com/" className="btn lkn" role="button">
							<Linkedin/>
						</a>
					</div>
					<br/><br/>
				</div>
			</div>
		</>
	);
};

export default Footer;