import React from "react";

const Step32 = props => {
    if (props.currentStep !== 32) {
        return null;
    }

    return (
        <>
        <div className="label">Voluntary Self-Identification of disability</div>
        <div className="stepinfo">
            <p><b>Why are you being asked to complete this form?</b></p>
            <p>
                We are a federal contractor or subcontractor required by law to 
                provide equal employment opportunity to qualified people with disablities. 
                We are also required to measure out progress toward 
                having at least 7% of our workforce be individuals with disabilities. 
                To do this, we must ask applicants and employees 
                if they have a disability or have ever had a disability. 
                Because a person may become disabled at any time, 
                we ask all of our employee to update their information at least every five years. 
                Identifying yourself as an individual with a disability is voluntary, 
                and we hope you will choose to do so. Your answer will be maintained confidentially 
                and not be seen by selecting officials or anyone else involved in making personnel decisions. 
                Completing the form will not negatively impact you in any way, 
                regardless of whether you have self-identified in the past. 
                For more information about this form or the equal employment obligations of federal contractors 
                under Section 503 of the Rehabilitation Act, visit the U.S. Department of Labor's Office 
                of Federal Contract Compliance Programs (OFCCP) website at www.dol.gov/ofccp.
            </p>
            <p>
                Your responses are entirely voluntary. 
                The information you provide shall be considered confidential 
                and will not be used in the application process.
            </p>
        </div>
        <div className="stepinfo">
            <p><b>How do you know if you have a disability?</b></p>
            <p>
                You are considered to have a disability if you have a phsical or mental impairment or medical condition 
                that substantially limits a major life acitvity, or if you have a history or record of such and impairment 
                or sub an dimpairment or midical condition. Disabilities include, but are not limited to: 
            </p>
            <p>
                Autism; Autoimmune disorder, for example, lupus, fibromyalgia, rheumatoid arthritis, or HIV/AIDS; 
                Blind or low vision; Cancer; Cardiovascular or heart disease; Celiac disease; Cerebral Palsy; 
                Deaf or hard of hearing; Depression or anxiedy; Diabetes; Epileps; 
                Gastrointestinal disorders, for example, Crohn's Disease, or irritable bowels synchome;
                Intellectual disability; Missing limbs or partially missing limbs; Nervous system condition for example, 
                migraine headaches, Parkingson's disease, or Multiple sclerosis (MS);
                Psychiatric condition, for example, bipolar disorder, schizophrenia, PTSD, or major depression.
            </p>
        </div>
        <div className="inputRow">
            <div style={{width: "40%"}}>
                <select 
                    name="DisabilityStatus"
                    id="DisabilityStatus"
                    value={props.data.DisabilityStatus}
                    onChange={props.handleChange}
                >
                    <option value=""></option>
                    <option>Yes, I Have A Disability, or Have A History/Record of Having A Disability</option>
                    <option>No, I Don't Have A Disability, or A History/Record of Having A Disability</option>
                    <option>I Don't Wish to Answer</option>
                </select>
            </div>
        </div>
    </>
)

};

export default Step32;