import React from "react";

const Step40 = props => {
    if (props.currentStep !== 40) {
        return null;
    }
    const {files, data} = props;

    return (
    <>
        <div className="label">Review</div>
        <div className="stepinfo">
            <div><b>My Documents</b></div>
            <div><i>Resume</i></div>
            <div className="list">{files.Resume && files.Resume.name}</div>
            <div><i>Cover Letter</i></div>
            <div className="list">{files.CoverLetter && files.CoverLetter.name}</div>
            <div><i>Additional Documents</i></div>
            {files.Documents && files.Documents.map((doc, index) =>
                <div key={index} className="list">{doc.name}</div>
            )}
            <p></p>
            <div><b>Personal Info</b></div>
            <div><i>Name</i></div>
            <div className="list">
                {data.FirstName + ' ' + data.MiddleName + ' ' + data.LastName}
            </div>
            
            {data.Address && data.Address.map((addr, index) => 
                <div key={index}>
                    <div><i>Address {(index>0) && (index+1)}</i></div>
                    <div className="list">
                       {addr.Street + ' ' + addr.City + ' ' + addr.ZipCode + ' ' + addr.Country + ' ' + addr.State}
                    </div>
                </div>
            )}

            <div><i>Contact</i></div>
            <div className="list">
                Phone:&nbsp;
                {data.Contact.Phone && data.Contact.Phone.map((number, index) => 
                    <span key={index}>
                        {((index>0)?', ':' ') + number}
                    </span>
                )}
            </div>
            <div className="list">
                Email:&nbsp;
                {data.Contact.Email && data.Contact.Email.map((e, index) => 
                    <span key={index}>
                        {((index>0)?', ':' ') + e}
                    </span>
                )}
            </div>
            <div><i>Are you legally authorized to work in the United States?</i></div>
            <div className="list">{data.LegaltoWork}</div>
            <div><i>How were you referred to us?</i></div>
            <div className="list">
                {data.Referral.ReferredFrom}
                {(data.Referral.ReferredFrom === "Website") && (": " + data.Referral.ReferWebsite)}
                {(data.Referral.ReferredFrom === "Company Employee") && (": " + data.Referral.ReferEmployee)}
            </div>

            <div><i>What date are you available to start work?</i></div>
            <div className="list">{data.DateStart}</div>
            <div><i>Expected Salary</i></div>
            <div className="list">{data.ExpSalary}</div>

            <div><i>Education</i></div>
            {data.Education && data.Education.map((edu, index) =>
                <div key={index}>
                    <div className="list">{edu.Name}</div>
                    <div className="list">{edu.Street + ' ' + edu.City + ' ' + edu.ZipCode + ' ' + edu.State}</div>
                    <div className="list">Degree: {edu.Degree}, Graduate Date: {edu.DateGraduate}</div>
                    <div className="list">Skills:</div>
                    <div className="list"><div style={{paddingLeft:"20px", whiteSpace: "pre-line"}}>{data.Skills}</div></div>
                </div>
            )}
            
            <div><i>Employment History</i></div>
            {data.EmpHistory && data.EmpHistory.map((emp, index) => 
                <div key={index}>
                    <div className="list">{emp.Employer}</div>
                    <div className="list">
                       {emp.Street + ' ' + emp.City + ' ' + emp.ZipCode + ' ' + emp.Country + ' ' + emp.State}
                    </div>
                    <div className="list">Title: {emp.Title}, From {emp.DateFrom} to {emp.DateTo}</div>
                    <div className="list">Current: {emp.Current}</div>
                    <div className="list">Responsibilities:</div>
                    <div className="list"><div style={{paddingLeft:"20px", whiteSpace: "pre-line"}}>{emp.Responsibilities}</div></div>
                    <div className="list">Reason For Leaving:</div>
                    <div className="list"><div style={{paddingLeft:"20px", whiteSpace: "pre-line"}}>{emp.LeaveReason}</div></div>
                </div>
            )}
            
            {data.Reference && data.Reference.map((ref, index) => 
                <div key={index}>
                    <div><i>Reference {(index>0) && (index+1)}</i></div>
                    <div className="list">{ref.FirstName + ' ' + ref.MiddleName + ' ' + ref.LastName}</div>
                    <div className="list">Phone Number: {ref.Phone}</div>
                    <div className="list">Email: {ref.Email}</div>
                </div>
            )}

            <p></p>
            <div><b>Job Specific Questions</b></div>
            <div><i>Gender</i></div>
            <div className="list">{data.Gender}</div>
            <div><i>Veteran Status</i></div>
            <div className="list">{data.VeteranStatus}</div>
            <div><i>Race/Ethnic Group</i></div>
            <div className="list">{data.Race}</div>
            <div><i>Voluntary Self-Identification of Disability</i></div>
            <div className="list">{data.DisabilityStatus}</div>
        </div>
    </>
    )
    
};

export default Step40;