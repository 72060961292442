import React from 'react';
import './css/App.css';

import { Routes, BrowserRouter, Route } from 'react-router-dom';

import Openings from './components/Openings';
import Opening from './components/Opening';
import Apply from './components/Apply';
import Applications from './components/Applications';
import Upload from './components/Upload';
// import UploadPDFTest from './components/UploadPDFTest';
import Header from './header_and_footer/Header';
import Footer from './header_and_footer/Footer';

function App() {
  return (    
    <div className="App">
      <Header/>
      <div className="layoutContainer">
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Openings />} />
            <Route path='/openings/*'>
              <Route index element={<Openings />}></Route>
              <Route path=":jobId" element={<Opening />} />
            </Route>
          <Route path='/apply' element={<Apply />} />
          <Route path='/upload' element={<Upload />} />
          <Route path='/applications' element={<Applications />} />
          </Routes>
        </BrowserRouter>
      </div> 
      <Footer/>
    </div>
  );
}

export default App;