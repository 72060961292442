import React from "react";
import {Form} from "react-bootstrap";

const Upload = () => {
    const apiUrl = "https://localhost:7186/upload";

    const handleSubmit = (e) => {
        e.preventDefault();

        const formdata = new FormData(e.target);
 
        fetch(apiUrl, {
            method: "POST",
            body: formdata
        })
        .then(r => r.json())
        .then(function (data) {
            console.log(data);
        })
        .catch((error) => {
            console.log(error);
        });
        
    }

    return (<Form onSubmit={handleSubmit}>
        <div>Application ID</div>
        <div><input type="text" name="AppId" style={{marginBottom:"20px"}}></input></div>
        <div>Resume: </div>
        <div><input type="file" name="Resume" style={{paddingBottom:"20px"}}></input></div>
        <div>Cover Letter: </div>
        <div><input type="file" name="CoverLetter" style={{paddingBottom:"20px"}}></input></div>
        <div>Additional Documents: </div>
        <div><input type="file" name="Documents" multiple style={{paddingBottom:"20px"}}></input></div>
        <div><input type="submit" value="Submit"></input></div>
    </Form>
    );
}

export default Upload;