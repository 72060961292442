import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Step22 = props => {
    const { data } = props;
    if (props.currentStep !== 22) {
        return null;
    }

    const { Education } = data;

    const educationdata = { "Education": Education };  // facilate change/add/delete

    const handleEducationChange = (e) => {
        const { name, value } = e.target;
        const index = e.target.closest('.record').getAttribute('index');
        const obj = e.target.closest('.record').getAttribute('obj');
        educationdata[obj][index][name] = value;
        props.handleObjectChange(obj, educationdata[obj]);
    }
    const handleEducationDateChange = (name, date, e) => {
        e.target.name = name;
        e.target.value = date.toLocaleDateString('en-US');
        handleEducationChange(e);
    }

    const addObject = (e) => {
        const obj = e.target.getAttribute('obj');
        if (obj === 'Education') {
            let copy = { ...educationdata[obj][0] };
            for (var key in copy) {
                if (copy.hasOwnProperty(key)) copy[key] = ''; // clear all fields
            }
            educationdata[obj].push(copy);
        }
        props.handleObjectChange(obj, educationdata[obj]);
    }

    const delObject = (e) => {
        const obj = e.target.closest('.record').getAttribute('obj');
        const index = e.target.closest('.record').getAttribute('index');
        educationdata[obj].splice(index, 1);
        props.handleObjectChange(obj, educationdata[obj]);
    }

    const handleDateChange = (name, date, e) => {
        e.target.name = name;
        e.target.value = date.toLocaleDateString('en-US');
        props.handleChange(e);
    }

    const handleSkillsChange = (e) => {
        props.handleChange(e);
    }

    return (
        <>
            <div className="label">Availability</div>
            <div className="inputRow">
                <div style={{ width: "40%", marginTop: "10px" }}>What date are you available to start work?</div>
            </div>
            <div className="inputRow">
                <div style={{ width: "40%" }}>
                    <DatePicker
                        type="text"
                        name="DateStart"
                        id="DateStart"
                        selected={data.DateStart && data.DateStart !== "" && new Date(data.DateStart)}
                        onChange={(date, e) => handleDateChange("DateStart", date, e)}
                    />
                </div>
            </div>
            <div className="inputRow">
                <div style={{ width: "40%" }}>Expected Salary</div>
            </div>
            <div className="inputRow">
                <div style={{ width: "40%" }}>
                    $ <input
                        type="text"
                        name="ExpSalary"
                        id="ExpSalary"
                        value={data.ExpSalary}
                        onChange={props.handleChange}
                    />
                </div>
            </div>
            <div className="label">
                Education
            </div>
            {Education && Education.map((educ, index) =>
                <div className="record" key={index} index={index} obj="Education">
                    <div className="label">
                        School {(index + 1)} {(index > 0) ? <span className="applyLink" onClick={delObject}>Remove</span> : null}
                    </div>
                    <div className="inputRow">
                        <div style={{ width: "40%", marginTop: "10px" }}>Name of School</div>
                    </div>
                    <div className="inputRow">
                        <div style={{ width: "40%", marginTop: "10px" }}>
                            <input
                                type="text"
                                name="Name"
                                id={`EducationName${index}`}
                                value={educ.Name}
                                onChange={handleEducationChange}
                            />
                        </div>
                    </div>
                    <div className="inputRow">
                        <div style={{ width: "60%" }}>Street</div>
                        <div style={{ width: "30%" }}>City</div>
                    </div>
                    <div className="inputRow">
                        <div style={{ width: "60%" }}>
                            <input
                                type="text"
                                name="Street"
                                id={`EducationStreet${index}`}
                                value={educ.Street}
                                onChange={handleEducationChange}
                            />
                        </div>
                        <div style={{ width: "18%" }}>
                            <input
                                type="text"
                                name="City"
                                id={`EducationCity${index}`}
                                value={educ.City}
                                onChange={handleEducationChange}
                            />
                        </div>
                    </div>
                    <div className="inputRow">
                        <div style={{ width: "20%" }}>State</div>
                        <div style={{ width: "20%" }}>Zip Code</div>
                    </div>
                    <div className="inputRow">
                        <div style={{ width: "20%" }}>
                            <input
                                type="text"
                                name="State"
                                id={`EducationState${index}`}
                                value={educ.State}
                                onChange={handleEducationChange}
                            />
                        </div>
                        <div style={{ width: "20%" }}>
                            <input
                                type="text"
                                name="ZipCode"
                                id={`EducationZipCode${index}`}
                                value={educ.ZipCode}
                                onChange={handleEducationChange}
                            />
                        </div>
                    </div>
                    <div className="inputRow">
                        <div style={{ width: "40%" }}>Degree</div>
                        <div style={{ width: "40%" }}>Graduation Date</div>
                    </div>
                    <div className="inputRow">
                        <div style={{ width: "40%" }}>
                            <input
                                type="text"
                                name="Degree"
                                id={`EducationDegree${index}`}
                                value={educ.Degree}
                                onChange={handleEducationChange}
                            />
                        </div>
                        <div style={{ width: "40%" }}>
                            {/* <input
                                type="text"
                                name="DateGraduate"
                                id={`EducationDateGraduate${index}`}
                                value={educ.DateGraduate}
                                onChange={handleEducationChange}
                            /> */}
                            <DatePicker
                                type="text"
                                name="DateGraduate"
                                id={`EducationDateGraduate${index}`}
                                selected={educ.DateGraduate && educ.DateGraduate !== "" && new Date(educ.DateGraduate)}
                                onChange={(date, e) => handleEducationDateChange("DateGraduate", date, e)}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className="inputRow">
                <span obj="Education" style={{ cursor: "pointer", color: "#7371FC" }} onClick={addObject}>+ Add More</span>
            </div>

            <div className="label">Skills</div>
            <div className="inputRow">
                <div style={{ width: "80%" }}>Skills and Qualifications: Licenses, Certifications, Skills, Training, Awards</div>
            </div>
            <div className="inputRow">
                <div style={{ width: "80%" }}>
                    <textarea
                        name="Skills"
                        id="EducationSkills"
                        onChange={handleSkillsChange}
                        value={data.Skills}
                    />
                </div>
            </div>

        </>
    )

};

export default Step22;