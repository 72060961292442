import React from "react";

const Step10 = props => {
  const {files} = props;

  const handleChange = (e) => {
    props.handleObjectChange(e.target.name, e.target.files[0]);
  }

  const addDocument = (e) => {
    if (!files.Documents) files.Documents = [];
    files.Documents.push.apply(files.Documents, e.target.files);
    props.handleObjectChange("Documents", files.Documents);
  }

  const delDocument = (e) => {
    const index = e.target.getAttribute("index");
    files.Documents.splice(index, 1);
    props.handleObjectChange("Documents", files.Documents);
  }

  let style = {display: "block"};
  if (props.currentStep !== 10) {
    style.display = "none";
  }

  return (
    <div style={style}>
      <div className="stepinfo">
        Accepted file types: DOCX, PDF, Image and Text (MSG, PPT and XLS file types are not accepted for resume or cover letters)
      </div>

      <div className="label">Resume - Required</div>
      <div className="file">
        {files.Resume?<div>File: {files.Resume.name}</div>:null}
        <label>
          <input type="file" name="Resume" id="resume" className="hidden" onChange={handleChange} />
          <span className="applyLink">+Upload</span>
        </label>
      </div>
      <div className="label">Cover Letter - Optional</div>
      <div className="file">
        {files.CoverLetter?<div>File: {files.CoverLetter.name}</div>:null}
        <label>
          <input type="file" name="CoverLetter" id="letter" className="hidden" onChange={handleChange} />
          <span className="applyLink">+Upload</span>
        </label>
      </div>
      <div className="label">Additional Documents - Optional. You can upload multiple files.</div>
      <div className="file">
        {files.Documents && files.Documents.map((doc, index) =>
            <div key={index}>File: {doc.name} <span className="applyLink" index={index} onClick={delDocument}>Remove</span></div>
        )}
        <label>
          <input type="file" name="Documents" id="document" multiple className="hidden" onChange={addDocument} />
          <span className="applyLink">+Upload</span>
        </label>
      </div>
    </div>
  );
};

export default Step10;
