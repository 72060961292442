import React, { useState, useEffect } from "react";
import { LinkContainer } from 'react-router-bootstrap';

import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import "../css/Openings.css";


const Applicatinos = () => {
    const [jobs, setJobs] = useState([]);
 
    useEffect(() => {
        fetchData();
    }, [])

    function fetchData() {
        fetch("https://localhost:7186/openings")
            .then(response => response.json())
            .then(function (data) {
                setJobs(data);
            })
    }

    return (<React.Fragment>
        <table><tr>
            <td className="float-left">Title</td>
            <td className="float-right">Location</td>
            <td className="float-right">Category</td>
        </tr></table>
        <Accordion defaultActiveKey="0">
            {jobs && jobs.map((job, index) =>
                <Accordion.Item key={job.id} eventKey={job.id}>
                    <Accordion.Header>
                        <table><tr>
                            <td className="float-left">{job.title}</td>
                            <td className="float-right">{job.location}</td>
                            <td className="float-right">{job.category}</td>
                        </tr></table>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p className="font-weight-bold">{job.title}</p>
                        <p>{job.category}</p>
                        <p>{job.location}</p>
                        <br></br>
                        {job.desc}
                        <br></br>
                        <p>Job Schedule: {job.type}</p>
                        <p>Position Request Date: {job.postingdate}</p>
                        <br></br>
                        <LinkContainer to="/ApplyForm" state={{ JobId: job.id, JobTitle: job.title, JobLocation: job.location, JobCategory: job.category }}>
                            <Button>Apply Now</Button>
                        </LinkContainer>
                   </Accordion.Body>
                </Accordion.Item>
            )}
        </Accordion>
    </React.Fragment>)
}

export default Applicatinos;