import React, { useState, useEffect, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap';
import Button from "react-bootstrap/Button";
import parse from 'html-react-parser';

import 'font-awesome/css/font-awesome.min.css';

export default function Opening() {
    const [job, setJob] = useState({});
    const location = useLocation();
    const params = useParams();

    const openingURL = process.env.REACT_APP_API_OPENINGS_URL;

    const fetchJob = useCallback(() => {    // useCallback to avoid missing dependency warning in useEffect 
        if (location.state != null && location.state.job != null)
            setJob(location.state.job);
        else {
            const jobId = params.jobId;
            fetch(openingURL)
            .then((response) => {
                if (response.ok)
                    return response.json();
                throw new Error('Something went wrong');
            })
            .then((data) => {
                var filteredData = data.filter((job) => String(job.id)===String(jobId));
                setJob(filteredData[0]);
            })
            .catch ((error) => {
                console.log(error);
            });
        }
    }, [location, params, openingURL]);

    useEffect(() => {
        fetchJob();
    }, [fetchJob]);

    return (
        <>
        <div>
        <a href="/openings" style={{textDecoration:"none"}}><i className="fa fa-arrow-left"></i> Back</a>
        </div>
        <div className="outContainer">
            <div className="inContainer" style={{display:"flex"}}>
                <div className="info">
                    <div className="title">{job.title}</div>
                    <div><span className="label">Salary{(String(job.dependsonexperience).toLowerCase() === "yes") ? " Depends on Experience" : ""}: </span>${Number(job.salarymin).toLocaleString()} - ${Number(job.salarymax).toLocaleString()}</div>
                    <div><span className="label">Job Schedule: </span>{job.type}</div>
                    <div><span className="label">Job Category: </span>{job.category}</div>
                    <div><span className="label">Job Location: </span>{job.location}</div>
                </div>
                <div className="button">
                    <LinkContainer to="/Apply" state={{ JobId: job.id, JobTitle: job.title, JobLocation: job.location, JobCategory: job.category }}>
                        <Button>Apply Now</Button>
                    </LinkContainer>
                </div>
            </div>

            <div className="inContainer desc">
                {(job && job.desc)?
                    parse(
                        job.desc
                        .replace(/&#(\d+);/g, function(match, dec) { // decode HTML encoding
                            return String.fromCharCode(dec); 
                        })
                        .replace(/font-family:[^;]*;/g, '') // clear inline font-family style
                    )
                    :null
                }
            </div>

            <div className="inContainer">
                {/* <div><span className="finePrint">{(String(job.dependsonexperience).toLowerCase() === "yes") ? "*Salary Depends on Experience" : ""}</span></div> */}
                <div><span className="label">Posting Date: </span>{new Date(job.postingdate).toDateString()}</div>
                <div><span className="label">Location: </span>{job.address}</div>
            </div>
        </div>
        </>
    );
}