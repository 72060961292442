import React from "react";
import {PatternFormat } from "react-number-format";

import states from "./UsStates";

const Step21 = props => {
    if (props.currentStep !== 21) {
        return null;
    }

    const {countries} = props;

    const {Address, Contact} = props.data;

    const data = {"Address":Address, "Contact":Contact};  // facilate change/add/delete

    const handleChange = (e) => {
        const { name, value } = e.target;
        const obj = e.target.closest('.record').getAttribute('obj');
        const index = e.target.closest('.record').getAttribute('index');
        if (obj === 'Address') {
            data[obj][index][name] = value;
        }
        if (obj === "Contact") {
            data[obj][name][index] = value;
        }
        props.handleObjectChange(obj, data[obj]);
    }

    const addObject= (e) => {
        const obj = e.target.getAttribute('obj');
        if (obj === 'Address') {
            let copy = {...data[obj][0]};
            for (var key in copy) {
                if (copy.hasOwnProperty(key)) copy[key] = ''; // clear all fields
            }
            data[obj].push(copy);
        }
        if (obj === "Contact") {
            const subobj = e.target.getAttribute('subobj');
            data[obj][subobj].push("");
        }
        props.handleObjectChange(obj, data[obj]);
    }

    const delObject = (e) => {
        const obj = e.target.closest('.record').getAttribute('obj');
        const index = e.target.closest('.record').getAttribute('index');
        if (obj === 'Address') {
            data[obj].splice(index, 1);
        }
        if (obj === "Contact") {
            const subobj = e.target.getAttribute('subobj');
            data[obj][subobj].splice(index, 1);
        }
        props.handleObjectChange(obj, data[obj]);
    }

    const {Referral} = props.data;

    const handleReferralChange = (e) => {
        const { name, value } = e.target;
        Referral[name] = value;
        props.handleObjectChange("Referral", Referral);
    }

    return (
    <>
        <div className="label">Name</div>
        <div className="labelRow">
            <div style={{width:"40%"}}>First</div>
            <div style={{width:"20%"}}>Middle</div>
            <div style={{width:"40%"}}>Last</div>
        </div>
        <div className="inputRow">
            <div style={{width:"40%"}}>
                <input
                    type="text"
                    name="FirstName"
                    id="FirstName"
                    placeholder="Enter your First Name"
                    value={props.data.FirstName}
                    onChange={props.handleChange}
                />
            </div>
            <div style={{width:"20%"}}>
                <input
                    type="text"
                    name="MiddleName"
                    id="MiddleName"
                    placeholder="Enter your Middle Name"
                    value={props.data.MiddleName}
                    onChange={props.handleChange}
                />
            </div>
            <div style={{width:"40%"}}>
                <input
                    type="text"
                    name="LastName"
                    id="LastName"
                    placeholder="Enter your Last Name"
                    value={props.data.LastName}
                    onChange={props.handleChange}
                />
            </div>
        </div>
        {Address && Address.map((addr, index) => 
            <div className="record" key={index} index={index} obj="Address">
                <div className="label">
                    Address {(index>0)?(index+1):null} {(index>0)?<span className="applyLink" onClick={delObject}>Remove</span>:null}
                </div>
                <div className="labelRow">
                    <div style={{width:"40%"}}>Street</div>
                    <div style={{width:"30%"}}>City</div>
                    <div style={{width:"29%"}}>Zip Code</div>
                </div>
                <div className="inputRow">
                    <div style={{width:"40%"}}>
                        <input
                            type="text"
                            name="Street"
                            id={`AddressStreet${index}`}
                            value={addr.Street}
                            onChange={handleChange}
                        />
                    </div>
                    <div style={{width:"30%"}}>
                        <input
                            type="text"
                            name="City"
                            id={`AddressCity${index}`}
                            value={addr.City}
                            onChange={handleChange}
                        />
                    </div>
                    <div style={{width:"29%"}}>
                        <input
                            type="text"
                            name="ZipCode"
                            id={`AddressZipCode${index}`}
                            value={addr.ZipCode}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="labelRow">
                    <div style={{width:"40%"}}>Country</div>
                    <div style={{width:"30%"}}>State/Province</div>
                </div>
                <div className="inputRow" index={index}>
                    <div style={{width:"40%"}}>
                        <select
                            type="text"
                            name="Country"
                            id={`AddressCountry${index}`}
                            value={addr.Country}
                            onChange={handleChange}
                        >
                            <option value=""></option>
                        {countries && countries.map((country, index) => 
                            <option key={index}>{country}</option>
                        )}
                        </select>
                    </div>
                    <div style={{width:"30%"}}>
                        {(addr.Country === "United States")?
                            <select
                            type="text"
                            name="State"
                            id={`AddressState${index}`}
                            value={addr.State}
                            onChange={handleChange}
                            >
                                <option value=""></option>
                            {states && states.map((state, index) => 
                                <option key={index} value={state.value}>{state.name}</option>
                            )}
                            </select>
                            :
                            <input
                            type="text"
                            name="State"
                            id={`AddressState${index}`}
                            value={addr.State}
                            onChange={handleChange}
                            />
                        }
                    </div>
                </div>
            </div>
        )}
{/* only need one address
        <div className="inputRow">
            <span obj="Address" className="applyLink" onClick={addObject}>+ Add More</span>
        </div>
*/}

        <div className="label">Contact</div>
        <div className="labelRow">
            <div>Phone Number</div>
        </div>
        {Contact.Phone && Contact.Phone.map((number, index) => 
            <div className="record" key={index} index={index} obj="Contact">
                <div className="inputRow">
                    <div style={{width:"30%"}}>
                        <PatternFormat 
                            format="(###)-###-####"
                            mask="_"
                            name="Phone"
                            id={`ContactPhone${index}`}
                            index={index}
                            value={number}
                            onChange={handleChange}
                        />
                    </div>
                    {(index>0)?<span subobj="Phone" className="applyLink" onClick={delObject}>Remove</span>:null}
                </div>
            </div>
       )}
        <div className="inputRow">
            <span obj="Contact" subobj="Phone" className="applyLink" onClick={addObject}>+ Add More</span>
        </div>

        <div className="labelRow">
            <div>Email</div>
        </div>
        {Contact.Email && Contact.Email.map((e, index) => 
            <div className="record" key={index} index={index} obj="Contact">
                <div className="inputRow">
                    <div style={{width:"30%"}}>
                        <input
                            type="text"
                            name="Email"
                            id={`ContactEmail${index}`}
                            value={e}
                            onChange={handleChange}
                        />
                    </div>
                    {(index>0)?<span subobj="Email" className="applyLink" onClick={delObject}>Remove</span>:null}
                </div>
            </div>
        )}
{/* disable multiple emails, so we can identify applicant by primary contact email.
        <div className="inputRow">
            <span obj="Contact" subobj="Email" className="applyLink" onClick={addObject}>+ Add More</span>
        </div>
*/}
        <div className="label">Other</div>
        <div className="labelRow">
            <div>Are you legally authorized to work in the United States?</div>
        </div>
        <div className="inputRow">
            <div style={{width:"30%"}}>
                <select
                    name="LegaltoWork"
                    id="LegaltoWork"
                    value={props.data.LegaltoWork}
                    onChange={props.handleChange}
                >
                    <option value=""></option>
                    <option>Yes</option>
                    <option>No</option>
                </select>
            </div>
        </div>
        
        {props.data.LegaltoWork === "No" && <>
        <div className="labelRow">
            <div>Will you now or in the future require sponsorship for employment visa status (e.g., H-1B visa status)?</div>
        </div>
        <div className="inputRow">
            <div style={{width:"30%"}}>
                <select
                    name="NeedsSponsor"
                    id="NeedsSponsor"
                    value={props.data.NeedsSponsor}
                    onChange={props.handleChange}
                >
                    <option value=""></option>
                    <option>Yes</option>
                    <option>No</option>
                </select>
            </div>
        </div>
        </>}

        <div className="label">How were you referred to us?</div>
        <div className="radioRow">
            <div className="radioCell">
                <input
                    type="radio"
                    name="ReferredFrom"
                    value="Professional Publication"
                    checked={'Professional Publication' === Referral.ReferredFrom}
                    onChange={handleReferralChange}
                />
                <span>Professional Publication</span>
           </div>
           <div className="radioCell">
                <input
                    type="radio"
                    name="ReferredFrom"
                    value="Job or Career Fair"
                    checked={'Job or Career Fair' === Referral.ReferredFrom}
                    onChange={handleReferralChange}
                />
                <span>Job or Career Fair</span>
           </div>
        </div>
        <div className="radioRow">
            <div className="radioCell">
                <input
                    type="radio"
                    name="ReferredFrom"
                    value="Placement Office"
                    checked={'Placement Office' === Referral.ReferredFrom}
                    onChange={handleReferralChange}
                />
                <span>Placement Office</span>
            </div>
            <div className="radioCell">
                <input
                    type="radio"
                    name="ReferredFrom"
                    value="Website"
                    checked={'Website' === Referral.ReferredFrom}
                    onChange={handleReferralChange}
                />
                <span>Website</span> 
                <select
                    name="ReferWebsite"
                    value={Referral.ReferWebsite}
                    onChange={handleReferralChange}
                >
                    <option value=""></option>
                    <option>Dice</option>
                    <option>Indeed</option>
                    <option>LinkedIn</option>
                    <option>Monster</option>
                    <option>ZipRecruiter</option>
                </select>
            </div>
        </div>
        <div className="radioRow">
            <div className="radioCell" style={{width: "60%"}}>
                <input
                    type="radio"
                    name="ReferredFrom"
                    value="Company Employee"
                    checked={'Company Employee' === Referral.ReferredFrom}
                    onChange={handleReferralChange}
                />
                <span>Company Employee (Name)</span>
                <span>
                    <input
                        type="text"
                        name="ReferEmployee"
                        id="ReferEmployee"
                        value={Referral.ReferEmployee}
                        onChange={handleReferralChange}
                    />
                </span>
            </div>
        </div>
        <div>
 
        </div>
   </>
  );
};

export default Step21;
