import React, { useState, useMemo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import countryList from 'react-select-country-list';

import { Button, Modal } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

import Step10 from "./Step10";
import Step21 from "./Step21";
import Step22 from "./Step22";
import Step23 from "./Step23";
import Step31 from "./Step31";
import Step32 from "./Step32";
import Step40 from "./Step40";

import ProgressBar from "./ProgressBar";

import "../css/Apply.css";

import MyDoc from "./UploadPDF";
import { pdf } from "@react-pdf/renderer";

const Apply = props => {
    const location = useLocation();
    const countries = useMemo(() => countryList().getLabels(), [])
    const applyURL = process.env.REACT_APP_API_APPLY_URL;

    const [currentStep, setCurrentStep] = useState(10);

    const [files, setFiles] = useState({

        Resume: null,
        CoverLetter: null,
        Documents: null,
        ApplicationPDF: null

    });

    const [formdata, setFormdata] = useState({
        JobId: location.state.JobId,

        FirstName: "",
        MiddleName: "",
        LastName: "",

        Address: [{
            Street: "",
            City: "",
            ZipCode: "",
            Country: "",
            State: ""
        }],

        Contact: {
            Phone: [""],
            Email: [""]
        },

        LegaltoWork: "",
        NeedsSponsor: "",

        Referral: {
            ReferredFrom: "",
            ReferEmployee: "",
            ReferWebsite: "",
        },

        DateStart: "",
        ExpSalary: "",

        Education: [{
            Name: "",
            Street: "",
            City: "",
            State: "",
            ZipCode: "",
            Degree: "",
            DateGraduate: "",
        }],
        
        Skills: "",

        EmpHistory: [{
            Employer: "",
            Superviser: "",
            Street: "",
            City: "",
            ZipCode: "",
            Country: "",
            State: "",
            Title: "",
            Current: false,
            DateFrom: "",
            DateTo: "",
            Responsibilities: "",
            LeaveReason: ""
        }],

        Reference: [{
            FirstName: "",
            MiddleName: "",
            LastName: "",
            Position: "",
            Phone: "",
            Email: ""
        }],

        Gender: "",
        VeteranStatus: "",
        Race: "",
        DisabilityStatus: ""

    });

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        let updatedValue = {};
        updatedValue = { [name]: value };
        if (type === "file") {
            setFiles(files => ({
                ...files,
                ...updatedValue
            }));
        }
        else {
            setFormdata(formdata => ({
                ...formdata,
                ...updatedValue
            }));
        }
    }

    const fileObject = ["Resume", "CoverLetter", "Documents", "ApplicationPDF"];
    const handleObjectChange = function (objName, objVal) {
        let updatedValue = {};
        updatedValue = { [objName]: objVal };
        if (fileObject.includes(objName)) {
            setFiles(files => ({
                ...files,
                ...updatedValue
            }));
        }
        else {
            setFormdata(formdata => ({
                ...formdata,
                ...updatedValue
            }));
        }
        // console.log(formdata);
    }
    function checkRequired() { // all required fields
        var okay = true;
        var alertstring = "At least one requirement is not met. Please go back and check:\n\n";
        console.log(formdata.ReferredFrom);
        if (files.Resume == null) {
            alertstring += "Resume: A resume is required.\n";
            okay = false;
        }
        if (formdata.FirstName === "" || formdata.LastName === "") {
            alertstring += "Name: Your full legal name is required.\n";
            okay = false;
        }
        if (formdata.Address[0].Street === "" || formdata.Address[0].City === "" || formdata.Address[0].ZipCode === "" || formdata.Address[0].Country === "") {
            alertstring += "Address: Your full address is required.\n";
            okay = false;
        }
        if (formdata.Contact.Email[0] === "") {
            alertstring += "Email: At least one email is required.\n";
            okay = false;
        }
        if (formdata.Contact.Phone[0] === "") {
            alertstring += "Phone: At least one phone number is required.\n";
            okay = false;
        }
        // if (formdata.LegaltoWork !== "Yes") {
        //     alertstring += "Legal to Work: You must affirm that you are legally authorized to work in the United States.\n";
        //     okay = false;
        // }
        if (formdata.Reference[0].FirstName.length < 1 || formdata.Reference[0].LastName.length < 1 || formdata.Reference[0].Position.length < 1 || formdata.Reference[0].Phone.length < 1 || formdata.Reference[0].Email.length < 1) {
            alertstring += "References: At least one reference is required. Please give the contact information of at least one reference,.including their full name, position, phone number, and email.\n";
            okay = false;
        }
        if (formdata.ReferredFrom === "") {
            alertstring += "Referral: Please let us know where you were referred to us.\n";
            okay = false;
        }
        // return files.Resume==null || formdata.FirstName == null || formdata.LastName == null || formdata.Address == null || formdata.Contact == null || formdata.LegaltoWork !== "Yes" || formdata.Reference == null || formdata.Reference[0].FirstName.length>0 || formdata.ReferredFrom == null;
        if (!okay) {
            //alert(alertstring);
            setShowError(true);
            setMessageError(alertstring);
        }
        return okay;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const token = recaptchaRef.current.getValue();
        if (token === '') {
            setCaptchaError(true);
            recaptchaRef.current.captcha.scrollIntoView();
            return false;
        }
        recaptchaRef.current.reset();

        let fd = new FormData();
        fd.append('recaptcha_token', token);

        for (let dataKey in formdata) {
            if (formdata[dataKey] instanceof Object)
                fd.append(dataKey, JSON.stringify(formdata[dataKey]));
            else
                fd.append(dataKey, formdata[dataKey]);
        }
        // pupulate first address details
        if (formdata["Address"].length > 0) {
            // populate first address details for applicant
            for (let subKey in formdata["Address"][0]) {
                fd.append(subKey, formdata["Address"][0][subKey]);
            }
        }

        // add skills separately again since it has a separate column in SharePoint
        // fd.append('Skills', formdata.Skills);
        //fd.append('Skills', formdata.Education.Skills.replace(/\n/g, "<br>"));

        if (files.Resume != null)
            fd.append('Resume', files.Resume);
        if (files.CoverLetter != null)
            fd.append('CoverLetter', files.CoverLetter);
        if (files.Documents != null) {
            for (let i = 0; i < files.Documents.length; i++) {
                fd.append('Documents', files.Documents[i], `${formdata.FirstName}_${formdata.LastName}_${files.Documents[i].name}}`);
                console.log(`${formdata.FirstName}_${formdata.LastName}_${files.Documents[i].name}}`);
            }
        }
        if (files.ApplicationPDF != null)
            fd.append('Documents', files.ApplicationPDF);
 
        if (checkRequired()) {
            setShow(true);
            setMessage('Submitting, please wait...');
            fetch(applyURL, {
                method: "POST",
                body: fd
            })
            .then((response) => {
                if (response.ok) {
                    return response.text();
                }
                console.log(response);
                throw new Error('Something went wrong');
            })
            .then((response) => {
                if (response === 'OK') {
                    setDisableModalButton(false);
                    setMessage('Your application is successfully submitted.');
                } else {
                    setMessage('Something went wrong: ' + response);
                }
            })
            .catch((error) => {
                console.log(error);
                setShow(true);
                setMessage('Something went wrong: ' + JSON.stringify(error));
            });
        }
    };

    const nextStep = {
        10: 21, 21: 22, 22: 23, 23: 31, 31: 32, 32: 40, 40: 40
    }

    const _next = () => {
        setCurrentStep(nextStep[currentStep]);
    };

    const prevStep = {
        10: 10, 21: 10, 22: 21, 23: 22, 31: 23, 32: 31, 40: 32
    }

    const _prev = () => {
        setCurrentStep(prevStep[currentStep]);
    };

    const previousButton = () => {
        if (currentStep !== 10) {
            return (
                <Button style={{ margin: "10px", backgroundColor: "#58040B" }} onClick={_prev}>
                    Previous
                </Button>
            );
        }
        return null;
    }

    const nextButton = () => {
        if (currentStep < 40) {
            return (
                <Button style={{ margin: "10px", backgroundColor: "#58040B" }} onClick={_next}>
                    Next
                </Button>
            );
        }
        return null;
    }

    const submitButton = () => {
        // If the current step is the last step, then render the "submit" Button
        if (currentStep >= 40) {
            return <Button color="primary" onClick={handleSubmit}>Submit</Button>;
        }
        return null;
    }

    // popup confirm dialog
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const [disableModalButton, setDisableModalButton] = useState(true);
    const handleClose = (e) => {
        setShow(false);
        if (e.target.id === "back") navigate('/openings');
    }
    // popup error dialog
    const [showError, setShowError] = useState(false);
    const [messageError, setMessageError] = useState('');
    const handleCloseError = (e) => {
        setShowError(false);
    }

    // ReCAPTHA
    const [captchaError, setCaptchaError] = useState(false);
    const recaptchaRef = React.createRef();
    const sitekey = process.env.REACT_APP_RECAPTHA_SITE_KEY;
    const onCaptchaChange = (e) => {
        if (recaptchaRef.current.getValue() === '') {
            setCaptchaError(true);
        } else {
            setCaptchaError(false);
        }
    }

    const { JobTitle, JobLocation, JobCategory } = location.state;
    const generatePDF = async () => {
        const blob = await pdf(<MyDoc data={formdata}/>).toBlob();
        const f = new File([blob], `${formdata.FirstName}_${formdata.LastName}_Application.pdf`);
        handleObjectChange("ApplicationPDF", f);
    }
    useEffect(() => {
        if (currentStep === 40) {
            generatePDF();
        }
    }, [currentStep]); // eslint-disable-line
    return (
        <>
             <div className="applyContainer">
                <div className="info">
                    <div className="title">{JobTitle}</div>
                    <div>{JobCategory}</div>
                    <div>{JobLocation}</div>
                </div>

                <div className="barOutContainer">
                    <div className="barInnerContainer">
                        <ProgressBar currentStep={currentStep} />
                    </div>
                    <div className="barLabelContainer">
                        <div className="labelRow">
                            <div className="label1">My Documents</div>
                            <div className="label2">Personal Info</div>
                            <div className="label3">Job Specific Questions</div>
                        </div>
                    </div>
                </div>

                <Step10
                    currentStep={currentStep}
                    files={files}
                    handleChange={handleChange}
                    handleObjectChange={handleObjectChange}
                />

                <Step21
                    currentStep={currentStep}
                    data={formdata}
                    countries={countries}
                    handleChange={handleChange}
                    handleObjectChange={handleObjectChange}
                />

                <Step22
                    currentStep={currentStep}
                    data={formdata}
                    handleChange={handleChange}
                    handleObjectChange={handleObjectChange}
                />

                <Step23
                    currentStep={currentStep}
                    data={formdata}
                    countries={countries}
                    handleChange={handleChange}
                    handleObjectChange={handleObjectChange}
                />

                <Step31
                    currentStep={currentStep}
                    data={formdata}
                    handleChange={handleChange}
                    handleObjectChange={handleObjectChange}
                />

                <Step32
                    currentStep={currentStep}
                    data={formdata}
                    handleChange={handleChange}
                    handleObjectChange={handleObjectChange}
                />

                <Step40
                    currentStep={currentStep}
                    files={files}
                    data={formdata}
                />

            </div>
            <div className="buttonContainer">
                {previousButton()}
                {nextButton()}
                {submitButton()}
            </div>
               <div className={`captchaContainer ${(currentStep >= 40)?'show':'hide'}`}>
                    <div style={{float: "right"}}>
                        <ReCAPTCHA 
                            ref={recaptchaRef} 
                            sitekey={sitekey}
                            onChange={onCaptchaChange}
                        />
                        {captchaError && <div style={{color:"red"}}>Please prove you are not bot.</div>}
                   </div>
                </div>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header>
                <Modal.Title>Job Application</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                <Button variant="primary" id="back" onClick={handleClose} disabled={disableModalButton}>
                    Back to Job Listings
                </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showError} onHide={handleCloseError}>
                <Modal.Header closeButton>
                <Modal.Title>Application Form Reminding</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{whiteSpace: "pre-wrap"}}>{messageError}</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseError}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default Apply;