import React from "react";

const Step31 = props => {
    if (props.currentStep !== 31) {
        return null;
    }

    return (
        <>
            <div className="label">Confidential EEO Self-Identification Form</div>
            <div className="stepinfo">
                <p>
                    You are invited to provide the following information to assist Stellar Services in meeting its government reporting requirements, 
                    to provide information regarding the demographics of our applicants, and to further advance our opportunity in balancing the diversity of our workforce. 
                    Although Stellar Services woul greatly appreciate your cooperation, your refusal to answer these questions will not subject you to adverse treatment, or, in any way, 
                    affect employment decisions made by company.
                </p>
                <p>
                    Your responses are entirely voluntary. 
                    The information you provide shall be considered confidential 
                    and will not be used in the application process.
                </p>
            </div>
            <div className="label">Gender</div>
            <br></br>        
            <div className="inputRow">
                <div style={{width: "40%"}} >
                <select 
                    name="Gender"
                    id="Gender"
                    value={props.data.Gender}
                    onChange={props.handleChange}
                >
                    <option value=""></option>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Decline to Self-Identify</option>
                </select>
                </div>
            </div>

            <div className="label">Veteran Status</div>
            <div className="stepinfo">
                <p>
                    If you believe you belong to any of the categories of protected veterans listed below, 
                    please indicate by making the appropriate selection. 
                    As a government contractor subject to Vietnam Era Veterans Readjustment Assistance Act (VEVRAA), 
                    we request this information in order to measure the effectiveness of the outreach 
                    and positive recruitment efforts we undertake pursuant to VEVRAA. 
                    Classification of protected categories is as follows:
                </p>
                <p>
                    A 'disabled veteran' is one of the following: a veteran of the U.S. military, ground, 
                    naval or air servcie who is entitled to compensation 
                    (or who but for the receipt of military retired pay would by entitled to compensation) 
                    under laws administered by the Secretary of Veterans Affairs; 
                    or a person who was discharged or released from active duty because of a service-connected disability. 
                    A 'recently separated veteran' means any vteran during the three-year period beginning on 
                    the date of such veteran's discharge or release from active duty in the U.S. military, ground, 
                    naval, or air service. 
                    An 'active-duty wartime or campaign badge veteran' means a veteran who served on active duty 
                    in the U.S. military, ground, naval, or air service during a war, or in a campaign 
                    or expedition for which a campaign badge has been authorized under the laws administered by the Department of Defense. 
                    An 'Armed forces service medal veteran' means a veteran who, while serving on active duty in the U.S. military, ground, 
                    naval, or air service, participated in a United States military operation for which 
                    an Armed Forcess service medal was awarded pursuant to Executive Order 12985.
                </p>
            </div>
            <div className="inputRow">
                <div style={{width: "40%"}}>
                    <select 
                        name="VeteranStatus"
                        id="VeteranStatus"
                        value={props.data.VeteranStatus}
                        onChange={props.handleChange}
                    >
                        <option value=""></option>
                        <option>I am not a protected veteran</option>
                        <option>I identify as one or more of the classes of a protected veteran</option>
                        <option>I do not wish to self-identify</option>
                    </select>
                </div>
            </div>

            <div className="label">Race/Ethnic Group</div>
            <div className="inputRow">
                <div style={{width: "40%", marginTop: "20px"}}>
                    <select 
                        name="Race"
                        id="Race"
                        value={props.data.Race}
                        onChange={props.handleChange}
                    >
                        <option value=""></option>
                        <option>American Indian or Alaska Native</option>
                        <option>Asian</option>
                        <option>Black or African American</option>
                        <option>Hispanic or Lation</option>
                        <option>White/Caucasian</option>
                        <option>Native Hawaiian/Other Pacific Islander</option>
                        <option>Two or More Races</option>
                        <option>I do not wish to self-identify</option>
                    </select>
                </div>
            </div>

        </>
    )
    
};

export default Step31;