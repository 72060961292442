import "./h_f_style.css";
import stellar_logo from "./Stellar_Logo.png";
// import stellarlogo from "./StellarLogo.png";

const Header = () => {
    return (
        <>
			<div className="Mobile d-block">
				<div className="container-fluid fixed-top scrolled Header">
					<nav className="navbar navbar-expand-lg s1">
						<div className="navbar-header">
							<a className="navbar-brand" href="https://www.stellarservices.com/">
								<img src={stellar_logo} className="navbar-brand logo s1" alt="Stellar Logo"/>
							</a>


						</div>

						<button className="navbar-toggler s2" type="button" data-toggle="collapse" data-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
							<i className="bi bi-list s3"></i>
						</button>

						<div className="collapse navbar-collapse s4" id="navbarSupportedContent1">
							<ul className="navbar-nav nav-tabs ml-auto" 
								style={{border: "none", marginLeft: "auto"}}
							>
								<li className="nav-item" 
									// style={{marginLeft: "auto", marginRight: "0"}}
								>
									<a className="nav-link" href="https://careers.stellarservices.com"><strong>CAREERS</strong></a>	
								</li>	
							</ul>
						</div>
					</nav>
				</div>
			</div>	

			<br/><br/><br/><br/><br/>
		</>
	);
};

export default Header;