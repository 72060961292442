import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
    ]
});

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#FFFFFF',
        padding: 35,
    },
    section: {
        // margin: 10,
    },
    heading1: {
        fontFamily: 'Open Sans',
        fontWeight: "bold",
        fontSize: 14,
    },
    heading2: {
        fontFamily: 'Open Sans',
        fontWeight: "bold",
        fontSize: 12,
        paddingLeft: 15,
    },
    heading3: {
        fontFamily: 'Open Sans',
        fontWeight: "bold",
        fontSize: 12,
        paddingLeft: 30,
    },
    body: {
        fontFamily: 'Open Sans',
        fontSize: 10,
        paddingLeft: 30,
    },
    body2: {
        fontFamily: 'Open Sans',
        fontSize: 10,
        paddingLeft: 45,
    },
});

// Create Document Component
const MyDoc = props => {
    const data = props.data;
    return (
        <Document>
            <Page size="A4" wrap style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.heading1}>Personal Info</Text>
                    <Text style={styles.heading2}>Name</Text>
                    <Text style={styles.body}>
                        {data.FirstName + ' ' + data.MiddleName + ' ' + data.LastName}
                    </Text>
                    
                    {data.Address && data.Address.map((addr, index) => 
                        <>
                            <Text style={styles.heading2}>Address {"\n"}{(index>0) && (index+1)}</Text>
                            <Text style={styles.body}>
                                {addr.Street + ' ' + addr.City + ' ' + addr.ZipCode + ' ' + addr.Country + ' ' + addr.State}
                            </Text>
                        </>
                    )}

                    <Text style={styles.heading2}>Contact</Text>
                    <Text style={styles.heading3}>
                        Phone:
                    </Text>
                        {data.Contact.Phone && data.Contact.Phone.map((number, index) => 
                            <Text key={index} style={styles.body2}>
                                {((index>0)?', ':' ') + number}
                            </Text>
                        )}
                    <Text style={styles.heading3}>
                        Email:
                    </Text>
                        {data.Contact.Email && data.Contact.Email.map((e, index) => 
                            <Text key={index} style={styles.body2}>
                                {((index>0)?', ':' ') + e}
                            </Text>
                        )}
                    <Text style={styles.heading2}>Are you legally authorized to work in the United States?</Text>
                    <Text style={styles.body}>{data.LegaltoWork}</Text>
                    <Text style={styles.heading2}>How were you referred to us?</Text>
                    <Text style={styles.body}>
                        {data.Referral.ReferredFrom}
                        {(data.Referral.ReferredFrom === "Website") && (": " + data.Referral.ReferWebsite)}
                        {(data.Referral.ReferredFrom === "Company Employee") && (": " + data.Referral.ReferEmployee)}
                    </Text>

                    <Text style={styles.heading2}>What date are you available to start work?</Text>
                    <Text style={styles.body}>{data.DateStart}</Text>
                    <Text style={styles.heading2}>Expected Salary</Text>
                    <Text style={styles.body}>${data.ExpSalary}</Text>

                    <Text style={styles.heading2}>
                        Education:
                    </Text>
                        {data.Education && data.Education.map((edu, index) =>
                            <div key={index}>
                                <Text style={styles.body}>{edu.Name}</Text>
                                <Text style={styles.body}>{edu.Street + ' ' + edu.City + ' ' + edu.ZipCode + ' ' + edu.State}</Text>
                                <Text style={styles.body}>Degree: {edu.Degree}, Graduate Date: {edu.DateGraduate}</Text>
                                <Text style={styles.body}>Skills:</Text>
                                <Text style={styles.body2}>{data.Skills}</Text>
                            </div>
                        )}
                    
                    <Text style={styles.heading2}>Employment History</Text>
                    {data.EmpHistory && data.EmpHistory.map((emp, index) => 
                        <div key={index}>
                            <Text style={styles.body}>{emp.Employer}</Text>
                            <Text style={styles.body}>
                                {emp.Street + ' ' + emp.City + ' ' + emp.ZipCode + ' ' + emp.Country + ' ' + emp.State}
                            </Text>
                            <Text style={styles.body}>Title: {emp.Title}, From {emp.DateFrom} to {emp.DateTo}</Text>
                            <Text style={styles.body}>Current: {emp.Current}</Text>
                            <Text style={styles.body}>Responsibilities:</Text>
                            <Text style={styles.body2}>{emp.Responsibilities}</Text>
                            <Text style={styles.body}>Reason For Leaving:</Text>
                            <Text style={styles.body2}>{emp.LeaveReason}</Text>
                        </div>
                    )}
                    
                    {data.Reference && data.Reference.map((ref, index) => 
                        <div key={index}>
                            <Text style={styles.heading2}>Reference {(index>0) && (index+1)}</Text>
                            <Text style={styles.body}>{ref.FirstName + ' ' + ref.MiddleName + ' ' + ref.LastName}</Text>
                            <Text style={styles.body}>Phone Number: {ref.Phone}</Text>
                            <Text style={styles.body}>Email: {ref.Email}</Text>
                        </div>
                    )}
                    
                    <Text style={styles.heading1}> </Text>
                    <Text style={styles.heading1}>Job Specific Questions</Text>
                        <Text style={styles.heading2}>Gender</Text>
                            <Text style={styles.body}>{data.Gender}</Text>
                        <Text style={styles.heading2}>Veteran Status</Text>
                            <Text style={styles.body}>{data.VeteranStatus}</Text>
                        <Text style={styles.heading2}>Race/Ethnic Group</Text>
                            <Text style={styles.body}>{data.Race}</Text>
                        <Text style={styles.heading2}>Voluntary Self-Identification of Disability</Text>
                            <Text style={styles.body}>{data.DisabilityStatus}</Text>
                </View>
            </Page>
        </Document>
    );
};

export default MyDoc;