import React from "react";

import "../css/ProgressBar.css";
import 'font-awesome/css/font-awesome.min.css';

const ProgressBar = props => {
    let stepPercentage = "0%";

    if (props.currentStep === 10) {
      stepPercentage = "0%";
    } else if (props.currentStep >= 20 && props.currentStep < 30) {
      stepPercentage = "50%";
    } else if (props.currentStep >= 30 && props.currentStep <= 40) {
      stepPercentage = "100%";
    } else {
      stepPercentage = 0;
    }
  
    return (
        <div className="progressBar">
            <div className="step" style={{left: "0%"}}>
                <div className={`indexedStep ${(props.currentStep >= 10) ? "ready" : null} ${(props.currentStep >= 20) ? "done" : null}`}>
                    {(props.currentStep >= 20) ? <i className="fa fa-check"></i> : 1}
                </div>
            </div>
            <div className="step" style={{left: "50%"}}>
                <div className={`indexedStep ${(props.currentStep >= 20) ? "ready" : null} ${(props.currentStep >= 30) ? "done" : null}`}>
                    {(props.currentStep >= 30) ? <i className="fa fa-check"></i> : 2}
                </div>
            </div>
            <div className="step" style={{left: "100%"}}>
                <div className={`indexedStep ${(props.currentStep >= 30) ? "ready" : null} ${(props.currentStep >= 40) ? "done" : null}`}>
                    {(props.currentStep >= 40) ? <i className="fa fa-check"></i> : 3}
                </div>
            </div>
            <div className="progression" style={{width: stepPercentage}}></div>
        </div>
    );
}

export default ProgressBar;