import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

import { Header, Table, HeaderRow, HeaderCell, Body, Row, Cell } from '@table-library/react-table-library/table';
import Button from "react-bootstrap/Button";
import "../css/Openings.css";

const Openings = () => {
    const [jobs, setJobs] = useState([]);
    const [jobsCopy, setJobsCopy] = useState([]);   // a backup of data from back end
    const openingURL = process.env.REACT_APP_API_OPENINGS_URL;
 
    const [dataloaded, setDataloaded] = useState(false);
    const fetchData = useCallback(() => { 
        let cached = false;

        let mydata = sessionStorage.mydata;
        let datatime = sessionStorage.datatime;
        if (mydata != null && datatime != null) {
            datatime = new Date(datatime);
            if ((Date.now() - datatime.valueOf() < 60000)) {    // cached data not older than 1 minute?
                cached = true;
                mydata = JSON.parse(mydata);
                setJobs(mydata);
                setJobsCopy(mydata);
                setDataloaded(true);
            }
        }

        if (!cached) {
            fetch(openingURL)
            .then((response) => {
                if (response.ok)
                    return response.json();
                throw new Error('Something went wrong');
            })
            .then(function (data) {
                 setJobs(data);
                 setJobsCopy(data);
                 setDataloaded(true);
                 sessionStorage.setItem("mydata", JSON.stringify(data));
                 sessionStorage.setItem("datatime", new Date().toString());
            })
            .catch ((error) => {
                console.log(error);
            });
        }
    }, [openingURL]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const [titleSearch, setTitleSearch] = React.useState('');

    const handleTitleSearch = (event) => {
        setTitleSearch(event.target.value.trim());
    };

    const [locationSearch, setLocationSearch] = React.useState('');

    const handleLocationSearch = (event) => {
        setLocationSearch(event.target.value.trim());
    };

    const handleSearch = () => {
        let data = [...jobsCopy];
         if (titleSearch !== '') { 
            data = data.filter((job) => job.title.toLowerCase().includes(titleSearch.toLowerCase()));
         }
        if (locationSearch !== '') {
            data = data.filter((job) => job.location.toLowerCase().includes(locationSearch.toLowerCase()));
        }
        setJobs(data);
    }
 
    return (<div className="openings_body">
        <div className="search">
            <div style={{width:"40%"}}>
                <div className="hint">Search by Job Title or Keyword:</div>
                <div><input type="text" onChange={handleTitleSearch} /></div>
            </div>
            <div style={{width:"40%"}}>
                <div className="hint">Search by Location:</div>
                <div><input type="text" onChange={handleLocationSearch} /></div>
            </div>
            <div style={{width:"20%"}}>
                <div className="button" ><Button onClick={handleSearch}>Search Jobs</Button></div>
            </div>
        </div>
        <Table data={{nodes: jobs}} className="openings">{(tableList) => (
            <>
                <Header>
                    <HeaderRow>
                        <HeaderCell>TITLE</HeaderCell>
                        <HeaderCell>CITY, STATE</HeaderCell>
                        <HeaderCell>JOB CATEGORY</HeaderCell>
                    </HeaderRow>
                </Header>
                <Body>
                    {!dataloaded && 
                        <Row>
                            <Cell>Loading...</Cell><Cell></Cell><Cell></Cell>
                        </Row>
                    }
                    {(dataloaded && tableList.length === 0) && 
                        <Row>
                            <Cell>There are no job openings at this moment. </Cell>
                            <Cell>Please come back later to check.</Cell>
                            <Cell></Cell>
                        </Row>
                    }
                    {tableList.map((job) => (
                        <Row key={job.id} job={job} >
                            <Cell><Link to={`/openings/${job.id}`} state={{job:job}} className="link"><div>{job.title}</div></Link></Cell>
                            <Cell><Link to={`/openings/${job.id}`} state={{job:job}} className="link"><div>{job.location}</div></Link></Cell>
                            <Cell><Link to={`/openings/${job.id}`} state={{job:job}} className="link"><div>{job.category}</div></Link></Cell>
                        </Row>
                    ))}
                </Body>
            </>
        )}
        </Table>
    </div>)
}

export default Openings;