import React from "react";
import DatePicker from "react-datepicker";
import { PatternFormat } from "react-number-format";
import "react-datepicker/dist/react-datepicker.css";

import states from "./UsStates";

const Step23 = props => {
    if (props.currentStep !== 23) {
        return null;
    }

    const { countries } = props;
    const { EmpHistory, Reference } = props.data;
    const data = { "EmpHistory": EmpHistory, "Reference": Reference };  // facilate change/add/delete

    const handleChange = (e) => {
        const { name, value } = e.target;
        const index = e.target.closest('.record').getAttribute('index');
        const obj = e.target.closest('.record').getAttribute('obj');
        data[obj][index][name] = value;
        props.handleObjectChange(obj, data[obj]);
    }

    const addObject = (e) => {
        const obj = e.target.getAttribute('obj');   
        let copy = { ...data[obj][0] };
        for (var key in copy) {
            if (copy.hasOwnProperty(key)) { // clear all fields
                if (typeof(copy[key]) == 'boolean')
                    copy[key] = false;
                else
                    copy[key] = '';
            }
        }
        data[obj].push(copy);
        props.handleObjectChange(obj, data[obj]);
    }

    const delObject = (e) => {
        const obj = e.target.closest('.record').getAttribute('obj');
        const index = e.target.closest('.record').getAttribute('index');
        data[obj].splice(index, 1);
        props.handleObjectChange(obj, data[obj]);
    }

    const handleDateChange = (name, date, e) => {
        console.log("date");
        e.target.name = name;
        e.target.value = date.toLocaleDateString('en-US');
        handleChange(e);
    }

    // same as handlechange, with boolean for current role
    const handleCurrentRoleChange = (e) => {
        const { name, checked } = e.target;
        const index = e.target.closest('.record').getAttribute('index');
        const obj = e.target.closest('.record').getAttribute('obj');

        data[obj][index][name] = checked;

        if (checked)    // if this true, then this is current job
            data[obj][index]["DateTo"] = "Present";
        else
            data[obj][index]["DateTo"] = "";    // reset DateTo field

        props.handleObjectChange(obj, data[obj]);
    }

    return (
        <>
            {EmpHistory && EmpHistory.map((emp, index) =>
                <div className="record" key={index} index={index} obj="EmpHistory">
                    <div className="label">
                        Employment History {(index > 0) ? (index + 1) : null} {(index > 0) ? <span className="applyLink" onClick={delObject}>Remove</span> : null}
                    </div>
                    <div className="labelRow">
                        <div style={{ width: "40%" }}>Employer</div>
                        <div style={{ width: "40%" }}>Superviser</div>
                    </div>
                    <div className="inputRow">
                        <div style={{ width: "40%" }}>
                            <input
                                type="text"
                                name="Employer"
                                id={`EmpHistoryEmployer${index}`}
                                value={emp.Employer}
                                onChange={handleChange}
                            />
                        </div>
                        <div style={{ width: "40%" }}>
                            <input
                                type="text"
                                name="Superviser"
                                id={`EmpHistorySuperviser${index}`}
                                value={emp.Superviser}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="labelRow">
                        <div style={{ width: "40%" }}>Street</div>
                        <div style={{ width: "30%" }}>City</div>
                        <div style={{ width: "29%" }}>Zip Code</div>
                    </div>
                    <div className="inputRow">
                        <div style={{ width: "40%" }}>
                            <input
                                type="text"
                                name="Street"
                                id={`EmpHistoryStreet${index}`}
                                value={emp.Street}
                                onChange={handleChange}
                            />
                        </div>
                        <div style={{ width: "30%" }}>
                            <input
                                type="text"
                                name="City"
                                id={`EmpHistoryCity${index}`}
                                value={emp.City}
                                onChange={handleChange}
                            />
                        </div>
                        <div style={{ width: "29%" }}>
                            <input
                                type="text"
                                name="ZipCode"
                                id={`EmpHistoryZipCode${index}`}
                                value={emp.ZipCode}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="labelRow">
                        <div style={{ width: "40%" }}>Country</div>
                        <div style={{ width: "30%" }}>State/Province</div>
                    </div>
                    <div className="inputRow" index={index}>
                        <div style={{ width: "40%" }}>
                            <select
                                type="text"
                                name="Country"
                                id={`EmpHistoryCountry${index}`}
                                value={emp.Country}
                                onChange={handleChange}
                            >
                                <option value=""></option>
                                {countries && countries.map((country, index) =>
                                    <option key={index}>{country}</option>
                                )}
                            </select>
                        </div>
                        <div style={{ width: "30%" }}>
                            {(emp.Country === "United States") ?
                                <select
                                    type="text"
                                    name="State"
                                    id={`EmpHistoryState${index}`}
                                    value={emp.State}
                                    onChange={handleChange}
                                >
                                    <option value=""></option>
                                    {states && states.map((state, index) =>
                                        <option key={index} value={state.value}>{state.name}</option>
                                    )}
                                </select>
                                :
                                <input
                                    type="text"
                                    name="State"
                                    id={`EmpHistoryState${index}`}
                                    value={emp.State}
                                    onChange={handleChange}
                                />
                            }
                        </div>
                    </div>
                    <div className="labelRow">
                        <input
                            type="checkbox"
                            name="Current"
                            id={`EmpHistoryCurrent${index}`}
                            checked={emp.Current}
                            onChange={handleCurrentRoleChange}
                        />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                        I am currently working in this role
                        </label>
                    </div>                    
                    <div className="labelRow">
                        <div style={{ width: "40%" }}>Position Title</div>
                        <div style={{ width: "30%" }}>From:</div>
                        <div style={{ width: "29%" }}>To:</div>
                    </div>
                    <div className="inputRow">
                        <div style={{ width: "40%" }}>
                            <input
                                type="text"
                                name="Title"
                                id={`EmpHistoryTitle${index}`}
                                value={emp.Title}
                                onChange={handleChange}
                            />
                        </div>
                        <div style={{ width: "30%" }}>
                            <DatePicker
                                type="text"
                                name="DateFrom"
                                id={`EmpHistoryDateFrom${index}`}
                                selected={emp.DateFrom && emp.DateFrom !== "" && new Date(emp.DateFrom)}
                                onChange={(date, e) => handleDateChange("DateFrom", date, e)}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                            />
                        </div>
                        {emp.Current
                            ?
                            <div style={{ width: "29%" }}>
                                <input
                                    type="text"
                                    name="DateTo"
                                    id={`EmpHistoryDateTo${index}`}
                                    value={emp.DateTo}

                                    disabled
                                />
                            </div>
                            :
                            <div style={{ width: "29%" }}>
                                <DatePicker
                                    type="text"
                                    name="DateTo"
                                    id={`EmpHistoryDateTo${index}`}
                                    selected={emp.DateTo && (emp.DateTo !== "" && emp.DateTo !== "Present") && new Date(emp.DateTo)}
                                    onChange={(date, e) => handleDateChange("DateTo", date, e)}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                />
                            </div>
                        }
                    </div>
                    <div className="labelRow">
                        <div>Responsibilities</div>
                    </div>
                    <div className="inputRow">
                        <div style={{ width: "80%" }}>
                            <textarea
                                name="Responsibilities"
                                id={`EmpHistoryResponsibilities${index}`}
                                onChange={handleChange}
                                value={emp.Responsibilities}
                            />
                        </div>
                    </div>
                    <div className="labelRow">
                        <div>Reason For Leaving</div>
                    </div>
                    <div className="inputRow">
                        <div style={{ width: "80%" }}>
                            <textarea
                                name="LeaveReason"
                                id={`EmpHistoryLeaveReason${index}`}
                                onChange={handleChange}
                                value={emp.LeaveReason}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className="inputRow">
                <span obj="EmpHistory" style={{ cursor: "pointer", color: "#7371FC" }} onClick={addObject}>+ Add More</span>
            </div>
            <div className="label">
                Three References - At least one is required
            </div>
            {Reference && Reference.map((ref, index) =>
                <div className="record" key={index} index={index} obj="Reference">
                    <div className="label">
                        Reference {(index + 1)} {(index > 2) ? <span className="applyLink" onClick={delObject}>Remove</span> : null}
                    </div>
                    <div className="labelRow">
                        <div style={{ width: "40%" }}>First</div>
                        <div style={{ width: "20%" }}>Middle</div>
                        <div style={{ width: "40%" }}>Last</div>
                    </div>
                    <div className="inputRow" index={index}>
                        <div style={{ width: "40%" }}>
                            <input
                                type="text"
                                name="FirstName"
                                id={`ReferenceFirstName${index}`}
                                value={ref.FirstName}
                                onChange={handleChange}
                            />
                        </div>
                        <div style={{ width: "20%" }}>
                            <input
                                type="text"
                                name="MiddleName"
                                id={`ReferenceMiddleName${index}`}
                                value={ref.MiddleName}
                                onChange={handleChange}
                            />
                        </div>
                        <div style={{ width: "40%" }}>
                            <input
                                type="text"
                                name="LastName"
                                id={`ReferenceLastName${index}`}
                                value={ref.LastName}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="labelRow">
                        <div>Title</div>
                    </div>
                    <div className="inputRow">
                        <div style={{ width: "80%" }}>
                            <input
                                type="text"
                                name="Position"
                                id={`ReferencePosition${index}`}
                                value={ref.Position}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="labelRow">
                        <div style={{ width: "40%" }}>Phone Number</div>
                        <div style={{ width: "40%" }}>Email</div>
                    </div>
                    <div className="inputRow">
                        <div style={{ width: "40%" }}>
                            <PatternFormat
                                type="text"
                                format="(###)-###-####"
                                mask="_"
                                name="Phone"
                                id={`ReferencePhone${index}`}
                                value={ref.Phone}
                                onChange={handleChange}
                            />
                        </div>
                        <div style={{ width: "40%" }}>
                            <input
                                type="text"
                                name="Email"
                                id={`ReferenceEmail${index}`}
                                value={ref.Email}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
            )}
            {(Reference.length < 3) ?
                <div className="inputRow">
                    <span obj="Reference" style={{ cursor: "pointer", color: "#7371FC" }} onClick={addObject}>+ Add More</span>
                </div>
                : null
            }
        </>
    )

};

export default Step23;